import { JsonRpcSigner } from '@ethersproject/providers'
import { Seaport } from '@opensea/seaport-js'
import { SEAPORT_CONDUIT_KEY, SEAPORT_KEY_TO_CONDUIT, SEAPORT_V15_CONTRACTS } from 'constants/electroSwapContracts'

export const getSeaport = (chainId?: number, signer?: JsonRpcSigner) => {
  if (!chainId || !signer) {
    return undefined
  }

  const seaport = new Seaport(signer, {
    conduitKeyToConduit: SEAPORT_KEY_TO_CONDUIT[chainId],
    balanceAndApprovalChecksOnOrderCreation: true,
    overrides: {
      contractAddress: SEAPORT_V15_CONTRACTS[chainId],
      defaultConduitKey: SEAPORT_CONDUIT_KEY[chainId],
    },
    seaportVersion: '1.5',
  })

  return seaport
}
