const env = window.location.href.includes('app') ? '' : window.location.href.includes('stage') ? '.stage' : '.local'

const hostname = window.location.href.includes('app')
  ? 'https://app.electroswap.io'
  : window.location.href.includes('stage')
  ? 'https://stage.electroswap.io'
  : 'http://localhost:3000'

const cacheBuster = process.env.REACT_APP_GIT_COMMIT_HASH?.substring(0, 6)

export const ELECTROSWAP_LIST = hostname + '/tokenlist' + env + '.json?v=' + cacheBuster
export const ELECTROSWAP_EXTENDED_LIST = hostname + '/tokenlist' + env + '.json?v=' + cacheBuster
const ELECTROSWAP_UNSUPPORTED_LIST = hostname + '/unsupported.tokens.json'

export const UNSUPPORTED_LIST_URLS: string[] = []

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [ELECTROSWAP_LIST]
export const DEFAULT_INACTIVE_LIST_URLS: string[] = [...UNSUPPORTED_LIST_URLS]

export const DEFAULT_LIST_OF_LISTS: string[] = [...DEFAULT_ACTIVE_LIST_URLS, ...DEFAULT_INACTIVE_LIST_URLS]
