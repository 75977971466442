/* eslint-disable import/no-unused-modules */
declare type AddressMap = {
  [chainId: number]: string
}

export const LOCKER_V2_CONTRACTS: AddressMap = {
  [52014]: '0x16ca736c8B181772009e598F37f137e9cD36AFAE',
  [5201420]: '0xea09eCd0060987651CF54863329fBA82561559D6',
}

export const LOCKER_V3_CONTRACTS: AddressMap = {
  [52014]: '0xfdB0d62Fc929fD53D266B969Bfe4250b205D0899',
  [5201420]: '0x150626A1E2B5831B5Bf3Db73133679987152acC5',
}

export const FOT_DETECTOR_CONTRACTS: AddressMap = {
  [52014]: '0x34dc8af1FFe9F71aB8B37F9Ea79c567ab64140b3',
  [5201420]: '0x6B60A34Bb42d40ed22f3239447340635DB19C780',
}

export const YIELD_FARM_CONTRACTS: AddressMap = {
  [52014]: '0xe653aC16B732876F58a1722d24801230fA96bc82',
  [5201420]: '0x4025ed69ce7DCdc147418e0e730E7575F9b14b78',
}

export const ELECTRIC_LEGENDS_CONTRACTS: AddressMap = {
  [52014]: '0x31cbb613D14cc85Cf3A8889007562E4B5cE9518b',
  [5201420]: '0xac3497017c8Af03005185Fc7760041A4bCFe19cd',
}

export const NFT_DIVIDEND_DISTRIBUTOR_CONTRACTS: AddressMap = {
  [52014]: '0xE9b373f2c59C1868FeB755Ee37f5A76b8931F2Bb',
  [5201420]: '0x313C506e612cD800aCdC9b6E3e9B2ceA255ab20F',
}

export const NFT_DIVIDEND_DISTRIBUTOR_V2_CONTRACTS: AddressMap = {
  [52014]: '0xc4065B310d64a02Ac4BF43CFd35C5Fe1A42811ea',
  [5201420]: '0x084BA4Db2EBbf3BB3b2b6D5C988eac8aB593a384',
}

export const SEAPORT_V15_CONTRACTS: AddressMap = {
  [52014]: '0x678748317e7fD5B7699D07e666087608B401cbFd',
  [5201420]: '0xc76D47dc6E2f79FF2570f9B4145Af90E673b4F24',
}

export const SEAPORT_CONDUIT_CONTROLLER_CONTRACTS: AddressMap = {
  [52014]: '0x9DB8cDFff27B68183CBb2a1a2b22945c63e6072F',
  [5201420]: '0xBfa8941A528192A55e3D5ef64483DEB1c9B7D829',
}

export const SEAPORT_KEY_TO_CONDUIT: any = {
  [52014]: {
    ['0xD6Cf49CbCF84B2cd2472a376B5f791689A0769d0000000000000000000000000']:
      '0x2941Cba4DD14B2C67b0802107f23144c70ED680F',
  },
  [5201420]: {
    ['0x888031006C1b2D7A8F66b4E4Af52d8711C557B65000000000000000000000000']:
      '0xf14B3f11CEabC2A2FD0A7b06708549D10091d237',
  },
}

export const SEAPORT_CONDUIT_KEY: AddressMap = {
  [52014]: '0xD6Cf49CbCF84B2cd2472a376B5f791689A0769d0000000000000000000000000',
  [5201420]: '0x888031006C1b2D7A8F66b4E4Af52d8711C557B65000000000000000000000000',
}

export const NFT_MARKETPLACE_FEE_RECEIVER: AddressMap = {
  [52014]: '0xc4065B310d64a02Ac4BF43CFd35C5Fe1A42811ea',
  [5201420]: '0x084BA4Db2EBbf3BB3b2b6D5C988eac8aB593a384',
}
